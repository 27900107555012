import "./global.scss";
import Chat from "./components/Chat/Chat";
import emoji from "./emoji.png";

function App() {
  return (
    <div className="App">
      <div className="intro col">
        <div className="wrapper">
          <div className="title">
            <h1>InsultGPT</h1>
            <img src={emoji} alt="" />
          </div>

          <p className="description">
            This is most definetly against OpenAI's TOS. Please use this tool
            with respect, and only in good fun. The bot will never insult race,
            religion or personal beliefs. It'll also refrain from using swear
            words.
          </p>

          <button
            className="cta"
            onClick={() => window.open("https://skxv.dev", "_blank")}
          >
            Check out my other projects
          </button>
        </div>
      </div>
      <Chat />
    </div>
  );
}

export default App;
