import React, { useEffect, useState, useRef } from "react";
import { Configuration, OpenAIApi } from "openai";
import "./Chat.scss";

const api = "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";

const configuration = new Configuration({
  apiKey: api,
});
const openai = new OpenAIApi(configuration);

const exampleCards = [
  "Joe Biden is the president of the united states. He's very old.",
  "Insult Ninja for how bad he is at Fortnite. Do it as a rhyming poem.",
  "Insult bot, insult yourself.",
];

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const input = useRef(null);
  const btn = useRef(null);

  const onSend = async () => {
    setLoading(true);
    if (input.current.value.length > 0) {
      const response = await openai.createCompletion({
        model: "text-davinci-003",
        prompt: `You create very hurtful insults. You don't hold back. The user will give you a description of a person, and your job is to tear that person apart. Create long and deep-cutting insults. Act like you're talking directly to the person.\n\nuser:${input.current.value}\nassistant: `,
        temperature: 0,
        max_tokens: 200,
        top_p: 1,

        frequency_penalty: 1.4,
        presence_penalty: 0,
      });
      setMessages([
        ...messages,
        {
          sender: "user",
          msg: input.current.value,
        },
        {
          sender: "bot",
          msg: response.data.choices[0].text,
        },
      ]);
      input.current.value = "";
    }
    setLoading(false);
  };

  useEffect(() => {
    let savedMessages = JSON.parse(localStorage.getItem("messages"));
    if (savedMessages) {
      setMessages(savedMessages);
    }
  }, []);

  useEffect(() => {
    if (messages.length > 0) {
      localStorage.setItem("messages", JSON.stringify(messages));
    }
  }, [messages]);

  return (
    <div className="col chat">
      <div className="messages">
        {messages.map((message, index) => (
          <>
            <div
              className={`messageContainer from-${message.sender}`}
              key={`message-${index}`}
            >
              <div className="message">{message.msg}</div>
            </div>
          </>
        ))}
        {loading && (
          <div className="messageContainer loading">
            <p>Currently loading insult...</p>
          </div>
        )}
        {!loading && messages.length == 0 && (
          <div className="examples">
            <div className="title">
              <h1>Get started</h1>
              <p>Here are some examples you can use</p>
            </div>
            <div className="cards">
              {exampleCards.map((card, index) => (
                <div
                  className="card"
                  key={`card-${index}`}
                  onClick={() => {
                    input.current.value = card;
                    btn.current.click();
                  }}
                >
                  {card}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="input">
        <button
          className="clear"
          disabled={loading}
          onClick={() => {
            setMessages([]);
            localStorage.removeItem("messages");
          }}
        >
          Clear
        </button>
        <input
          type="text"
          placeholder="Enter the description of a person..."
          ref={input}
          disabled={loading}
          onKeyUp={(e) => {
            if (e.keyCode == 13) {
              e.preventDefault();
              onSend();
            }
          }}
        />
        <button
          className="send umami--click--send-message"
          onClick={() => onSend()}
          disabled={loading}
          ref={btn}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default Chat;
